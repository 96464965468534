@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    font-family: "Rubik", sans-serif;
    box-sizing: border-box;
}


body {
    background: #F8F8F8;
}
