.partnersMain {
  div {
    .table {
      width: 100%;

      .thead {
        border-bottom: 1px solid #ededed;

        .tr {
          font-size: 12px;
          text-align: center;
          line-height: 16px;
          font-weight: 500;
          color: #737c86;
          position: relative;

          .th_id {
            position: sticky;
            left: 0;
          }

          .thName {
            width: 178px;
            height: 56px;
            padding: 12px 24px;
            display: flex;
            justify-content: center;
            font-size: 12px;
            position: sticky;
            left: 60px;
          }

          .th {
            height: 56px;
            text-align: center;
            font-weight: 500;
            line-height: 16px;
            font-size: 12px;
            text-align: center;
            // padding: 12px 10px;
            width: 120px;

          }
        }
      }

      .tbody {
        .body_tr {
          font-size: 14px;
          height: 74px;
          font-weight: 500;
          color: #1e3148;
          border-bottom: 1px solid #ededed;

          .body_tb_id {
            position: sticky;
            left: 0;
            width: 60px;
            text-align: center;
          }

          .body_tbName {
            width: 178px;
            position: sticky;
            left: 60px;

            .blink {
              animation: blinker 0.3s alternate infinite;
            }

            @keyframes blinker {
              25% {
                background: #ff0000;
              }

              100% {
                background: #cb1818;
              }
            }
          }

          .body_tb {
            width: 120px !important;
            text-align: center;
            padding: 16px 12px;
          }
        }
      }
    }
  }
}